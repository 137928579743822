<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="6" :sm="24">
            <a-form-item label="原仓库">
              <a-select
                show-search
                allow-clear
                v-model="queryParam.from_warehouse_id"
                :not-found-content="warehouse_fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                placeholder="仓库名称"
                @search="this.warehouseFromSearch"
                @change="handleFromWarehouseChange"
              >
                <a-spin v-if="warehouse_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in from_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="目标仓库">
              <a-select
                show-search
                allow-clear
                :not-found-content="warehouse_fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                v-model="queryParam.to_warehouse_id"
                placeholder="仓库名称"
                @search="this.warehouseToSearch"
                @change="handleToWarehouseChange"
              >
                <a-spin v-if="warehouse_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in to_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0" allow-clear>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.transfer_inventory_status">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDetail(record)">详情</a>
          <a-divider type="vertical" v-if="record.status !== 3"/>
          <a-popconfirm
            v-if="record.status !== 3"
            :title="record.status === 1 ? '确定要出库吗？' : '确定要入库吗？'"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleEdit(record)"
          >
            <a>{{ record.status === 1 ? '出库' : '入库' }}</a>
          </a-popconfirm>
<!--          <a @click="handleEdit(record)" v-if="record.status !== 3">状态变更</a>-->
          <a-divider type="vertical" v-if="record.status === 3"/>
          <a @click="handleExport(record)" v-if="record.status === 3">导出</a>
          <a-divider type="vertical" v-if="record.status === 1"/>
          <a-popconfirm
            v-if="record.status === 1"
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <a-modal v-model="select_status_visible" title="请选择状态" ok-text="确认" cancel-text="取消" @ok="select_status">
      <a-select style="width: 90%" @change="handleSelectChange" v-model="select_status_data">
        <a-select-option
          v-for=" (val, key) in this.$Dictionaries.transfer_inventory_status"
          :key="key"
          :value="key">
          {{ val }}
        </a-select-option>
      </a-select>
    </a-modal>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/views/c-wms/trans_inventory/modules/CreateForm'
import {
  transfer_inventory_create,
  transfer_inventory_delete,
  transfer_inventory_list,
  transfer_inventory_update,
  transfer_inventory_detail_export
} from '@/api/c_wms_trans_inventory'
import { warehouse_list } from '@/api/c_wms_warehouse'
import { Base64 } from 'js-base64'
import debounce from 'lodash/debounce'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    this.warehouseFromSearch = debounce(this.handleFromWarehouseSearch, 800)
    this.warehouseToSearch = debounce(this.handleToWarehouseSearch, 800)
    return {
      loading: false,
      confirmLoading: false,
      // 创建窗口控制
      visible: false,
      select_status_visible: false,
      select_edit_record: {},
      select_status_data: '',
      mdl: {},
      warehouse_fetching: false,
      from_wms_warehouse: [],
      to_wms_warehouse: [],
      // 查询参数
      queryParam: {
        from_warehouse_id: undefined,
        to_warehouse_id: undefined
      },
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '批次',
          ellipsis: true,
          width: 200,
          dataIndex: 'batch'
        },
        {
          title: '原仓库',
          ellipsis: true,
          width: 150,
          dataIndex: 'from_warehouse'
        },
        {
          title: '目标仓库',
          width: 150,
          ellipsis: true,
          dataIndex: 'to_warehouse'
        },
        {
          width: 120,
          title: '总量',
          dataIndex: 'count',
          ellipsis: true
        },
        {
          width: 100,
          title: '创建人',
          dataIndex: 'create_by',
          ellipsis: true
        },
        {
          width: 100,
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.transfer_inventory_status[text] || '无'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return transfer_inventory_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.handleFromWarehouseSearch(undefined)
    this.handleToWarehouseSearch(undefined)
  },
  methods: {
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleDetail (record) {
      this.$router.push({ path: '/zb/wms/transfer_inventory_detail', query: { record: Base64.encode(JSON.stringify(record)) } })
    },
    handleExport (record) {
      transfer_inventory_detail_export({ transfer_id: record.id }).then((res) => {
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/octet-stream' })
        console.log(blob)
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `调拨单.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    handleEdit (record) {
      // this.select_status_visible = true
      // this.select_edit_record = record
      // console.log('修改状态')
      // this.select_status_data = '' + this.select_edit_record.status
      const param = {
        status: Number(record.status) + 1
      }
      if (param.status > 3) {
        this.$message.warning('调拨单状态错误')
        return
      }
      transfer_inventory_update(param, record.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleSelectChange (value) {
      this.select_status_data = value
      console.log(this.select_status_data, typeof this.select_status_data)
    },
    select_status () {
      const param = {
        status: Number(this.select_status_data)
      }
      if (param.status - this.select_edit_record.status > 1) {
        this.$message.warning('调拨单不能跨状态变更')
        return
      }
      transfer_inventory_update(param, this.select_edit_record.id).then((res) => {
        this.select_status_visible = false
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleInCancel () {
      this.purchase_form_visible = false
      const form = this.$refs.createCommodityPurchaseModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleDelete (record) {
      transfer_inventory_delete(record.id).then((res) => {
        console.log(res)
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          transfer_inventory_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleFromWarehouseSearch (value) {
      this.from_wms_warehouse = []
      this.warehouse_fetching = true
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.from_wms_warehouse = result
      }).finally(() => {
        this.warehouse_fetching = false
      })
    },
    handleToWarehouseSearch (value) {
      this.to_wms_warehouse = []
      this.warehouse_fetching = true
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.to_wms_warehouse = result
      }).finally(() => {
        this.warehouse_fetching = false
      })
    },
    handleFromWarehouseChange (value) {
      if (value === undefined) {
        this.handleFromWarehouseSearch(undefined)
      }
    },
    handleToWarehouseChange (value) {
      if (value === undefined) {
        this.handleToWarehouseSearch(undefined)
      }
    }
  }
}
</script>
