<template>
  <a-modal
    title="新建调拨单"
    :width="780"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="原仓库" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-select
                show-search
                allow-clear
                :not-found-content="warehouse_fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                v-decorator="[
                  'from_warehouse_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入仓库名称"
                @search="this.warehouseFromSearch"
                @change="handleFromWarehouseChange"
              >
                <a-spin v-if="warehouse_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in from_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="目标仓库" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-select
                show-search
                allow-clear
                :not-found-content="warehouse_fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                v-decorator="[
                  'to_warehouse_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入仓库名称"
                @search="this.warehouseToSearch"
                @change="handleWmsToWarehouseChange"
              >
                <a-spin v-if="warehouse_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in to_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="批次" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['batch', { rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { warehouse_list } from '@/api/c_wms_warehouse'
import moment from 'moment/moment'
import debounce from 'lodash/debounce'

// 表单字段
const fields = ['batch', 'from_warehouse_id', 'count', 'to_warehouse_id']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.warehouseFromSearch = debounce(this.handleFromWarehouseSearch, 800)
    this.warehouseToSearch = debounce(this.handleToWarehouseSearch, 800)
    return {
      warehouse_fetching: false,
      form: this.$form.createForm(this),
      from_wms_warehouse: [],
      to_wms_warehouse: [],
      from_wms_warehouse_id: 0,
      to_wms_warehouse_id: 0,
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      }
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleFromWarehouseSearch(undefined)
    this.handleToWarehouseSearch(undefined)
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleFromWarehouseSearch (value) {
      this.from_wms_warehouse = []
      this.warehouse_fetching = true
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.from_wms_warehouse = result
      }).finally(() => {
        this.warehouse_fetching = false
      })
    },
    handleToWarehouseSearch (value) {
      this.to_wms_warehouse = []
      this.warehouse_fetching = true
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.to_wms_warehouse = result
      }).finally(() => {
        this.warehouse_fetching = false
      })
    },
    handleFromWarehouseChange (value) {
      this.from_wms_warehouse_id = value
      if (value === undefined) {
        this.handleFromWarehouseSearch(undefined)
      }
      this.handleGenerateBatch()
    },
    handleWmsToWarehouseChange (value) {
      this.to_wms_warehouse_id = value
      if (value === undefined) {
        this.handleToWarehouseSearch(undefined)
      }
      this.handleGenerateBatch()
    },
    handleGenerateBatch () {
      if (this.from_wms_warehouse_id > 0 && this.to_wms_warehouse_id > 0) {
        const today = moment()
        const year = today.year()
        const month = today.month() + 1 // 注意月份从0开始，所以要加1
        const date = today.date() > 9 ? today.date() : '0' + today.date()
        this.form.setFieldsValue({
          batch: this.from_wms_warehouse_id + '' + this.to_wms_warehouse_id + year + month + date
        })
      } else {
        this.form.setFieldsValue({
          batch: undefined
        })
      }
    }
  }
}
</script>
