<template>
  <div>
    <a-form :form="form" style="width: 60%; margin: 0 auto;">
      <a-row :gutter="[16,16]">
        <a-col :span="10" :offset="5">
          <a-form-item label="货品">
            <a-input v-decorator="['wms_supervised_warehouse_goods', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="批次" >
            <a-input v-decorator="['batch', {rules: [{required: false, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="商品编号">
            <a-input v-decorator="['gcode', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="账册备案序号">
            <a-input v-decorator="['item_record_no', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="报关单号" >
            <a-input v-decorator="['customs_no', {rules: [{required: false, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="品牌、规格、型号" >
            <a-input v-decorator="['model', {rules: [{required: false, message: '必填项，请填写信息'}, {max:250, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="售卖单位" >
            <a-input v-decorator="['unit', {rules: [{required: false, message: '必填项，请填写信息'}, {max:4, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="售卖单位与法定单位的比例" >
            <a-input v-decorator="['rate', {rules: [{required: false, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" style="width: 150px" />
          </a-form-item>
          <a-form-item label="每单位净重（千克）" >
            <a-input v-decorator="['net_weight', {rules: [{required: false, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" style="width: 150px" />
          </a-form-item>
          <a-form-item label="每单位毛重（千克）" >
            <a-input v-decorator="['gross_weight', {rules: [{required: false, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" style="width: 150px" />
          </a-form-item>
          <a-form-item label="总量" >
            <a-input v-decorator="['total_count', {rules: [{required: false, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="实际入仓数量" v-if="false">
            <a-input v-decorator="['inbound_count', {rules: [{required: false, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
          </a-form-item>

          <a-form-item label="是否已入库" v-if="false">
            <a-switch v-decorator="['is_complete', {initialValue: false, valuePropName: 'checked'}]" checked-children="开" un-checked-children="关"/>
          </a-form-item>
          <a-form-item label="开启保质期" >
            <a-switch v-decorator="['open_self_life', {initialValue: false, valuePropName: 'checked'}]" checked-children="开" un-checked-children="关"/>
          </a-form-item>
          <a-form-item label="保质期开始时间" extra="格式: YYYY-mm-dd">
            <a-date-picker
              v-decorator="['self_life_begin', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
              show-time
              :disabled-date="disabledStartDate"
              format="YYYY-MM-DD"
              placeholder="开始时间"
              :open="startOpen"
              @openChange="handleStartOpenChange"
            />
          </a-form-item>
          <a-form-item label="保质期结束时间" extra="格式: YYYY-mm-dd">
            <a-date-picker
              v-decorator="['self_life_end', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
              show-time
              :disabled-date="disabledEndDate"
              format="YYYY-MM-DD"
              placeholder="结束时间"
              :open="endOpen"
              @openChange="handleEndOpenChange"
            />
          </a-form-item>
          <a-form-item label="条形码">
            <a-input v-decorator="['barcode', {rules: [{required: false, message: '必填项，请填写信息'}, {max:16, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="账册编号" >
            <a-input v-decorator="['ems_no', {rules: [{required: false, message: '默认使用配置账册'}, {max:64, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="备注" >
            <a-textarea v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}, {max:256, message: '输入超出长度限制'}]}]" :auto-size="{ minRows: 3, maxRows: 5 }"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item
        v-bind="buttonCol"
        style="width: 60%; margin: 0 auto"
      >
        <a-row>
          <a-col span="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="10">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="8"></a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import { goods_update } from '@/api/c_wms_goods'
// 表单字段
const fields = ['id', 'name', 'en_name', 'code', 'original_place', 'hs_code', 'unit', 'open_self_life', 'item_record_no', 'gcode',
  'shelf_life', 'shelf_life_unit', 'tariff_rate', 'consumption_tax_rate', 'value_added_tax_rate', 'is_active', 'total_count', 'serial', 'remark'
]
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      endOpen: false,
      startOpen: false,
      location: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      this.loadEditInfo(this.record)
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        console.log('--------------------', values)
        goods_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
    })
      })
    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, fields)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    },
    disabledStartDate (startValue) {
      const endValue = this.form.getFieldValue('self_life_end')
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate (endValue) {
      const startValue = this.form.getFieldValue('self_life_begin')
      console.log(startValue.valueOf())
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange (open) {
      console.log(open)
      this.startOpen = open
    },
    handleEndOpenChange (open) {
      this.endOpen = open
    }
  }
}
</script>
